import axios from 'axios'
import settings from '../settings/apis.js'

class LsportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].lsportsClientApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json'
            }
        });
        this.ProviderApi = axios.create({
            baseURL: settings[process.env.NODE_ENV].lsportsProviderApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getSports(startDate, endDate, resultsOnly) {
        return this.Api.get('/monitor/getLiveEventsSports/' + startDate + '/' + endDate+'/'+resultsOnly)
    }
    getEvents(startDate, endDate, sportId, inOfferFlag, resultsOnly,offerNumber, searchName) {
        return this.Api.get('/monitor/getLiveEvents/' + startDate + '/' + endDate + '/' + sportId + '/' +inOfferFlag+'/'+resultsOnly
        +'/'+offerNumber+'/'+searchName)
    }  
    setFixtureSubscription(subscriptionRequest) {
        return this.Api.post('/monitor/setFixtureSubscription', subscriptionRequest);
    }
    saveTVOfferSettings(subscriptionRequest){
        return this.Api.post('/monitor/setTVOfferSettings', subscriptionRequest);
    }
    
    tryGetResults(fixtureId){
        var request = {
            ClientName: "",
            FixtureIds : [fixtureId]
        };
        return this.Api.post('/utils/getLiveFixturesSettlement', request);


    }
    saveNewName(fixtureId, participant1New, participant2New, participant1Position, participant2Position)
    {
        var request = 
        {
            FixtureId: fixtureId,
            Participant1: participant1New,
            Participant2:participant2New,
            Participant1Position: participant1Position,
            Participant2Position:participant2Position
        }
        return this.Api.post('/monitor/updateEventName/',request);
    }

    //providerApi
    reSendProviderResults(fixtureId){
        return this.ProviderApi.get('/lsports/settleInPlayFixture/'+fixtureId);

    }
    getEntitiesWithPagination(params){
        return this.ProviderApi.get("/sportsbetting/getEntitiesWithPagination", {params});
    }
    getEntityWithTranslations(id, type){
        return this.ProviderApi.get("/sportsbetting/getEntityWithTransaltions/"+type+"/"+id);
    }
    updateEntity(entityType, entityDetails)
    {
        var request={
            entityType:entityType,
            entity:entityDetails
        };
        return this.ProviderApi.post("/sportsbetting/updateEntity",request);

    }
    importLeagues()
    {
        return this.ProviderApi.get("/lsports/getLeagues/true");
    }
    getLiveEvent(fixtureId){
        return this.ProviderApi.get("/sportsbetting/getLiveEvent/"+fixtureId);
    }
    suspendBets(suspendedBetsArray, fixtureId){
        var request={
            OutcomesToSuspend:suspendedBetsArray,
            FixtureId: fixtureId
        };
        console.log(request);
        return this.ProviderApi.post("/sportsbetting/suspendOutcomes",request);
    }


}

export {
    LsportsApi
}
