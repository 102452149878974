<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-lsports-sports"
                    v-model:expandedRows="expandedRows"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-8 p-text-right">
                                <Button v-show="entityType == 'league'" :label="$t('buttons.importNewLeagues')" class="p-button-raised p-button-warning" @click="importLeagues()" />
                            </div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>

                    <Column field="id" :header="$t('sportsSettings.id')" :sortable="true"></Column>
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column field="sortNo" :header="$t('sportsSettings.sortOrder')" :sortable="true"></Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <template #expansion="slotProps">
                        <SportEntityDetails :id="slotProps.data.id" :type="this.entityType"></SportEntityDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { LsportsApi } from '../../../service/LsportsApi';
import { uiSettings } from '../../../settings/uiSettings';
import { store } from '../../../store/index';
import { debounce } from 'lodash';
import SportEntityDetails from './SportEntityDetails.vue';
export default {
    name: 'sportEntities',
    components: { SportEntityDetails },
    props: {
        entityType: {
            type: String,
            Required: true,
        },
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            LsportsApi: new LsportsApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            expandedRows: [],
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        crtRoute() {
            return 'lsports' + this.entityType;
        },
    },
    mounted() {
        if (this.storeFilterData != undefined) {
            this.searchTerm = this.notNullOrEmptyField(this.storeFilterData.data.search) ? this.storeFilterData.data.search : '';
            this.sort = this.storeFilterData.data.sort;
            this.readItems(this.storeFilterData.data.pageNo);
        } else {
            this.readItems();
        }
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            const params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                sort: this.sort,
                entityType: this.entityType,
            };
            this.LsportsApi.getEntitiesWithPagination(params).then((response) => {
                this.items = response.data.data;
                this.totalPages = response.data.total;
                this.loading = false;
            });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        onFilter() {},
        composeAndSaveFiltersToStore() {
            let filters = {
                pageNo: this.crtPage,
                search: this.searchTerm,
                sort: this.sort,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        importLeagues() {
            this.LsportsApi.importLeagues()
                .then(() => {
                    this.showToast('success', this.$t('alerts.okAction'));
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        search: debounce(function () {
            this.readItems();
        }, 400),
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>
