<template>
    <div class="p-card p-grid p-shadow-3 p-pl-2">
        <Toast />
        <div class="p-col-6 p-xs-12">
            <div class="p-field p-grid">
                <label for="sortOrder" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }} </label>
                <div class="p-col-12 p-md-4">
                    <InputText style="width: 100%" id="sortOrder" type="number" v-model="entity.sortNo" />
                </div>
            </div>
            <h6 class="p-col-12">{{ $t('lsports.editTranslations') }}</h6>
            <div class="p-field p-grid" v-for="(translation, index) in entity.translations" :key="'translation_' + index">
                <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ getLanguageName(translation.languageId) }} </label>
                <div class="p-col-12 p-md-4">
                    <InputText style="width: 100%" type="text" v-model="translation.value" />
                </div>
            </div>
        </div>
        <div class="p-col-6 p-xs-12">
            <h6 class="p-col-12">{{ $t('lsports.addTranslations') }}</h6>
            <Button @click="newTranslation()" :label="$t('buttons.add')" class="p-button-raised p-button-warning p-mr-2 p-mb-2" />
            <div class="p-field p-grid" v-for="(translation, index) in newTranslations" :key="'newTranslation_' + index">
                <div class="p-col-12 p-md-4">
                    <Dropdown style="width: 100%" v-model="translation.language" :options="languages" optionLabel="name" :placeholder="$t('lsports.language')" :filter="!isMobile()" />
                </div>
                <div class="p-col-12 p-md-4">
                    <InputText style="width: 100%" type="text" v-model="translation.value" />
                </div>
            </div>
        </div>
        <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
    </div>
</template>

<script>
import { LsportsApi } from '../../../service/LsportsApi';
export default {
    name: 'sportEntityDetails',
    data() {
        return {
            entity: {},
            categoriesDictionary: {},
            LsportsApi: new LsportsApi(),
            languages: [
                { id: 1, name: 'English' },
                { id: 3, name: 'Russian' },
                { id: 4, name: 'Georgian' },
                { id: 5, name: 'Japanese' },
                { id: 6, name: 'German' },
                { id: 9, name: 'Spanish' },
                { id: 10, name: 'French' },
                { id: 11, name: 'Turkish' },
                { id: 12, name: 'Armenian' },
                { id: 13, name: 'SimplifiedChinese' },
                { id: 16, name: 'TraditionalChinese' },
                { id: 17, name: 'Korean' },
                { id: 18, name: 'Vietnamese' },
                { id: 19, name: 'Thai' },
                { id: 21, name: 'Indonesian' },
                { id: 22, name: 'Malay' },
                { id: 29, name: 'Portuguese' },
                { id: 30, name: 'Italian' },
                { id: 31, name: 'Croatian' },
                { id: 32, name: 'Slovak' },
                { id: 33, name: 'Slovenian' },
                { id: 34, name: 'Norwegian' },
                { id: 35, name: 'Swedish' },
                { id: 36, name: 'Danish' },
                { id: 37, name: 'Dutch' },
                { id: 38, name: 'Polish' },
                { id: 39, name: 'Czech' },
                { id: 40, name: 'Latvian' },
                { id: 41, name: 'Finnish' },
                { id: 42, name: 'Bosnian' },
                { id: 43, name: 'Hungarian' },
                { id: 44, name: 'Bulgarian' },
                { id: 45, name: 'Serbian' },
                { id: 46, name: 'Greek' },
                { id: 47, name: 'Romanian' },
                { id: 48, name: 'Estonian' },
                { id: 49, name: 'Macedonian' },
                { id: 50, name: 'Lithuanian' },
                { id: 51, name: 'Arabic' },
                { id: 52, name: 'Azerbaijan' },
                { id: 53, name: 'Hebrew' },
                { id: 54, name: 'Albanian' },
                { id: 55, name: 'Ukrainian' },
                { id: 56, name: 'SerbianLatin' },
                { id: 57, name: 'Montenegrin' },
                { id: 58, name: 'Belarus' },
                { id: 66, name: 'German(Switzerland)' },
                { id: 67, name: 'Spanish(Argentina)' },
                { id: 68, name: 'Spanish(Chile)' },
                { id: 69, name: 'Spanish(Colombia)' },
                { id: 70, name: 'Spanish(Mexico)' },
                { id: 71, name: 'Spanish(Uruguay)' },
                { id: 72, name: 'French(Switzerland)' },
                { id: 73, name: 'Portuguese(Brazil)' },
                { id: 74, name: 'Italian(Switzerland)' },
                { id: 75, name: 'Basque' },
                { id: 76, name: 'Belarusian' },
                { id: 77, name: 'Farsi' },
                { id: 78, name: 'Hindi' },
                { id: 79, name: 'Malayalam' },
                { id: 80, name: 'Punjabi' },
                { id: 81, name: 'Maltese' },
            ],
            newTranslations: [],
        };
    },

    props: {
        id: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.LsportsApi.getEntityWithTranslations(this.id, this.type).then((response) => {
            this.entity = response.data;
        });
    },
    methods: {
        getLanguageName(languageId) {
            var language = this.languages.find((l) => l.id == languageId);
            if (this.notNullOrEmptyObject(language)) {
                return language.name;
            }
            return 'undefined';
        },
        newTranslation() {
            this.newTranslations.push({
                value: '',
                language: {},
            });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        submitForm() {
            var duplicates = false;
            if (this.newTranslations.length > 0) {
                this.newTranslations.forEach((item) => {
                    if (this.entity.translations.find((t) => t.languageId == item.language.id.toString()) != undefined) {
                        duplicates = true;
                    } else {
                        this.entity.translations.push({ languageId: item.language.id.toString(), value: item.value });
                    }
                });
            }
            if (duplicates) {
                this.showToast('error', this.$t('lsports.alerts.duplicateLanguages'));
            } else {
                this.entity.sortNo = parseInt(this.entity.sortNo);
                this.LsportsApi.updateEntity(this.type, this.entity)
                    .then(() => {
                        this.showToast('success', this.$t('lsports.alerts.updateOk'));
                        this.newTranslations = [];
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('lsports.alerts.updateError'));
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
